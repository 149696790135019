import $ from 'jquery';
import 'select2/dist/js/select2.full';
import 'select2/dist/css/select2.css';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['trackingSource', 'trackingReference'];

  connect() {
    this.trackingSourceTarget.addEventListener(
      'change',
      this.filterTrackingReferences.bind(this)
    );
  }

  filterTrackingReferences() {
    const { value: source } = this.trackingSourceTarget;
    const allOptions = this.trackingReferenceTarget.querySelectorAll('option');
    allOptions.forEach((e) => e.setAttribute('disabled', 'disabled'));
    const validOptions = this.trackingReferenceTarget.querySelectorAll(
      `option.tracking-ref-${source}`
    );
    validOptions.forEach((e) => e.removeAttribute('disabled'));
    $(this.trackingReferenceTarget).prop('disabled', validOptions.length === 0);
  }
}
