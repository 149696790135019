import $ from 'jquery';
import 'select2/dist/js/select2.full';
import 'select2/dist/css/select2.css';
import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    $(this.element).select2({
      placeholder: 'Search for sic code',
      width: '100%',
    });
  }
}
