import { disable, undisable } from './disable';
import {
  addRequiredAttributesToInputs,
  removeRequiredAttributesFromInputs,
} from './requiredAttributes';

const hide = (
  el,
  { removeRequired = false, flex = false, disableInputs = false }
) => {
  el.classList.add('d-none');
  const showClass = flex ? 'd-flex' : 'd-block';
  el.classList.remove(showClass);
  if (removeRequired) {
    removeRequiredAttributesFromInputs(el);
  }

  if (disableInputs) {
    const inputs = el.getElementsByTagName('input');
    for (let i of inputs) {
      disable(i);
    }
  }
};

const show = (
  el,
  { addRequired = false, flex = false, undisableInputs = false }
) => {
  el.classList.remove('d-none');
  const showClass = flex ? 'd-flex' : 'd-block';
  el.classList.add(showClass);

  if (addRequired) {
    addRequiredAttributesToInputs(el);
  }

  if (undisableInputs) {
    const inputs = el.getElementsByTagName('input');
    for (let i of inputs) {
      undisable(i);
    }
  }
};

export { show, hide };
