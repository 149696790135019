import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['unavailableInput', 'collectionForm'];

  connect() {
    this.linked = this.element.dataset.linked === 'true';
    if (this.linked) {
      this.collectionFormTarget
        .querySelectorAll('input, select, textarea')
        .forEach((e) => e.setAttribute('disabled', 'disabled'));
    }
  }

  anytimeOkToggled(e) {
    if (e.currentTarget.checked) {
      this.unavailableInputTarget.setAttribute('disabled', 'disabled');
    } else {
      this.unavailableInputTarget.removeAttribute('disabled', 'disabled');
    }
  }
}
