const addRequiredAttributesToInputs = (formElement, inputElements = []) => {
  const formElements =
    inputElements.length > 0 ? inputElements : getFormElementsFor(formElement);
  setInputElementsRequiredAttr(formElements, true);
};

const removeRequiredAttributesFromInputs = (formElement) => {
  const formElements = getFormElementsFor(formElement);
  setInputElementsRequiredAttr(formElements, false);
};

const getFormElementsFor = (formElement) =>
  formElement.querySelectorAll('select,input');

const setInputElementsRequiredAttr = (inputElements, required) => {
  return inputElements.forEach((e) => (e.required = required));
};

export { addRequiredAttributesToInputs, removeRequiredAttributesFromInputs };
