import '../../leads/grid.scss';
import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.element.addEventListener('click', this.sortHeaderClicked.bind(this));
  }

  async sortHeaderClicked(e) {
    const href = e.currentTarget.href;
    history.replaceState(null, '', href);
  }
}
