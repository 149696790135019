import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'line1',
    'line2',
    'city',
    'county',
    'postcode',
    'companySelect',
    'companyName',
    'companyNumber',
    'companyAddress',
    'searchButton',
  ];

  connect() {
    this.companiesSearchUrl = this.element.dataset.companiesSearchUrl;
    this.companySelectTarget.addEventListener(
      'change',
      this.companySelected.bind(this)
    );
  }

  companySelected(e) {
    const selectedOptionElement = this.companySelectTarget.querySelector(
      `option[value='${e.currentTarget.value}']`
    );
    const company = JSON.parse(selectedOptionElement.dataset.address);
    this.line1Target.value = company.address.address_line_1;
    if (company.address.premises) {
      this.line1Target.value = `${company.address.premises}, ${this.line1Target.value}`;
    } else {
      this.line1Target.value = '';
    }
    if (company.address.address_line_2) {
      this.line2Target.value = company.address.address_line_2;
    } else {
      this.line2Target.value = '';
    }
    if (company.address.locality) {
      this.cityTarget.value = company.address.locality;
    } else {
      this.cityTarget.value = '';
    }
    if (company.address.region) {
      this.countyTarget.value = company.address.region;
    } else {
      this.countyTarget.value = '';
    }
    this.postcodeTarget.value = company.address.postal_code;
    this.companyNameTarget.value = company.title;
    this.companyNumberTarget.value = company.company_number;

    this.companyAddressTarget.classList.remove('d-none');
  }

  businessTypeSelected(e) {
    const businessType = e.currentTarget.value;
    if (businessType !== 'Sole Trader') {
      this.companyNumberTarget.removeAttribute('disabled');
      this.companyNumberTarget.placeholder = 'Company registration number';
      this.setupCompaniesSelectDropdown({
        placeholder: 'Click Search to find your company',
        disabled: true,
      });
    } else {
      this.companySelectTarget.value = null;
      this.setupCompaniesSelectDropdown({
        placeholder: 'Company selection not required for sole trader',
        disabled: true,
      });
      this.companyNumberTarget.setAttribute('disabled', 'disabled');
      this.companyNumberTarget.value = null;
      this.companyNumberTarget.placeholder =
        'Company number not required for sole trader';
      this.companyAddressTarget.classList.remove('d-none');
    }
  }

  manualEdit() {
    this.companyAddressTarget.classList.remove('d-none');
  }

  async search(e) {
    e.preventDefault();
    const r = await fetch(
      `${this.companiesSearchUrl}?company_name=${this.companyNameTarget.value}`
    );
    const companies = await r.json();
    this.setupCompaniesSelectDropdown({ companies });
  }

  setupCompaniesSelectDropdown({
    companies = [],
    placeholder = 'Select your company',
    disabled = false,
  }) {
    this.companySelectTarget
      .querySelectorAll('option')
      .forEach((option) => option.remove());
    this.companySelectTarget.add(new Option(placeholder, ''));
    if (companies && companies.length > 0) {
      companies.forEach((c) => {
        const option = new Option(c.text, c.id);
        option.dataset.address = JSON.stringify(c);
        this.companySelectTarget.add(option);
      });
      this.companyAddressTarget.classList.add('d-none');
    }
    this.companySelectTarget.disabled = disabled;
  }
}
