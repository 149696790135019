import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.contractLoadPath = this.element.dataset.contractLoadPath;
    this.contractStatusElement = this.element;
    this.timer = setTimeout(this.loadContract.bind(this), 5000);
  }

  disconnect() {
    this.timer = null;
  }

  async loadContract() {
    const r = await fetch(this.contractLoadPath);
    if (r.status != 200) {
      this.disconnect();
      return;
    }

    this.contractStatusElement.innerHTML = await r.text();
    this.timer = setTimeout(this.loadContract.bind(this), 5000);
  }
}
