import { Notyf } from 'notyf';
import { Controller } from 'stimulus';
import $ from 'jquery';
import { show, hide } from '../../utils/display';
export default class extends Controller {
  static targets = [
    'previewContainer',
    'sendTo',
    'sendButton',
    'customField',
    'customFieldsForm',
    'customFieldsFormContainer',
  ];

  connect() {
    this.leadPreviewBaseUrl = this.element.dataset.leadPreviewBaseUrl;
    this.leadSendEmailUrl = this.element.dataset.leadSendEmailUrl;
    this.quoteGuid = this.element.dataset.quoteGuid;
    this.previewingWithCustomData = false;
    this.notify = new Notyf({
      duration: 3000,
      position: {
        x: 'right',
        y: 'top',
      },
    });
  }

  editableTemplateSelected(e) {
    this.previewTemplateName = e.currentTarget.value;
    // find and show custom fields for this template
    this.customFieldsFormContainerTargets.forEach((t) => {
      const shouldShow = t.dataset.templateName == this.previewTemplateName;
      if (shouldShow) {
        show(t, {});
      } else {
        hide(t, {});
      }
    });
    this.previewingWithCustomData = true;
    this.clearPreview();
  }

  clearPreview() {
    this.setPreview('');
  }

  setPreview(html) {
    const doc = this.previewContainerTarget.contentWindow.document;
    doc.open();
    doc.write(html);
    doc.close();
  }

  templateSelected(e) {
    e.preventDefault();
    this.clearPreview();
    this.previewTemplateName = e.currentTarget.value;
    this.previewingWithCustomData = false;
    this.customFieldsFormContainerTargets.forEach((t) => hide(t, {}));
    this.showPreview();
  }

  previewEditableTemplate(e) {
    e.preventDefault();
    this.showPreview();
  }

  async sendEmail() {
    const formData = this.getCurrentFormData();
    const token = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute('content');
    const r = await fetch(this.leadSendEmailUrl, {
      method: 'put',
      body: formData,
      headers: { 'X-CSRF-Token': token },
    });
    if (r.status === 200) {
      this.notifyUser('success', 'Email queued');
      $('#send-customer-email-modal').modal('hide');
    } else {
      this.notifyUser('error', 'Failed to queue email');
    }
  }

  notifyUser(status, msg) {
    this.notify[status](msg);
  }

  getCurrentFormData() {
    const templateName = this.previewTemplateName;
    const customFieldsForm = this.previewingWithCustomData
      ? this.customFieldsFormTargets.find(
          (t) => t.dataset.templateName === templateName
        )
      : null;
    const formData = this.previewingWithCustomData
      ? new FormData(customFieldsForm)
      : new FormData();
    formData.set('template_name', templateName);
    if (this.quoteGuid) {
      formData.set('quote_guid', this.quoteGuid);
    }
    return formData;
  }

  async showPreview() {
    const formData = this.getCurrentFormData();
    const params = new URLSearchParams(formData);
    const url = `${this.leadPreviewBaseUrl}?${params.toString()}`;
    const r = await fetch(url);
    const preview = await r.json();
    this.setPreview(this.htmlDecode(preview.preview_html));
    this.sendToTarget.innerHTML = `Email will be sent to: ${preview.send_to}`;
    if (preview.can_send) {
      this.sendButtonTarget.removeAttribute('disabled');
    } else {
      this.sendButtonTarget.setAttribute('disabled', 'disabled');
    }
  }

  htmlDecode(encodedHtml) {
    const doc = new DOMParser().parseFromString(encodedHtml, 'text/html');
    return doc.documentElement.textContent;
  }
}
