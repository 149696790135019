import $ from 'jquery';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['wasteType'];

  connect() {
    this.customerNeedsAttentionPath =
      this.element.dataset.customerNeedsAttentionPath;
  }

  async submitForm(e) {
    e.preventDefault();

    const checkedWasteTypeElements = this.wasteTypeTargets.filter(
      (el) => el.checked
    );
    const otherTypes = ['other', 'not sure'];
    if (
      checkedWasteTypeElements.some((el) =>
        otherTypes.includes(el.dataset.wasteType.toLowerCase())
      )
    ) {
      await fetch(this.customerNeedsAttentionPath);
      $('#need-help-modal').modal('show');
    } else {
      if (this.wasteTypeTargets.every((el) => el.reportValidity())) {
        e.currentTarget.form.submit();
      }
    }
  }
}
