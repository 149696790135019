import Flatpickr from 'flatpickr';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [];

  connect() {
    Flatpickr(this.element, {
      enableTime: true,
      minTime: '09:00',
      maxTime: '17:30',
      dateFormat: 'd M Y H:i',
    });
  }
}
