import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['numLiftsPerWeek'];

  frequencyUpdated(e) {
    if (['daily', 'on_demand'].includes(e.currentTarget.value)) {
      this.numLiftsPerWeekTarget.removeAttribute('disabled');
      if (e.currentTarget.value === 'daily') {
        this.numLiftsPerWeekTarget.value = '7';
      } else {
        this.numLiftsPerWeekTarget.value = '1';
      }
    } else {
      this.numLiftsPerWeekTarget.setAttribute('disabled', 'disabled');
      this.numLiftsPerWeekTarget.value = null;
    }
  }
}
