import $ from 'jquery';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['addressSelect', 'quoteButton', 'wasteTypeToggle'];

  connect() {
    this.selectedCount = parseInt(this.element.dataset.numServicesSelected);
    this.addressSelectionComplete =
      this.element.dataset.addressConfirmed === 'true';
    const wasteToggled = (e) => {
      const offClicked = e.currentTarget.value === 'no';
      if (offClicked && this.selectedCount > 0) {
        this.selectedCount += -1;
      }
      if (!offClicked) {
        this.selectedCount += 1;
      }
      this.enableNextButtonIfAppropriate();
    };
    $(this.wasteTypeToggleTargets).on('change', wasteToggled.bind(this));

    if (this.hasAddressSelectTarget) {
      this.addressSelectTarget.addEventListener(
        'change',
        this.addressSelected.bind(this)
      );
    }
    this.enableNextButtonIfAppropriate();
  }

  addressSelected(e) {
    const addressIndex = e.currentTarget.value
      ? parseInt(e.currentTarget.value) + 1
      : 0;

    this.addressSelectionComplete = addressIndex > 0;
    this.enableNextButtonIfAppropriate();
  }

  wasteTypeToggled(e) {}

  enableNextButtonIfAppropriate() {
    if (this.selectedCount >= 1 && this.addressSelectionComplete) {
      this.quoteButtonTarget.removeAttribute('disabled');
    } else {
      this.quoteButtonTarget.setAttribute('disabled', 'disabled');
    }
  }
}
