import { Notyf } from 'notyf';
import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    const { flash } = this.element.dataset;
    const flashObj = JSON.parse(flash);
    const notyf = new Notyf({
      duration: 3000,
      position: {
        x: 'right',
        y: 'top',
      },
    });
    flashObj.forEach((msg) => {
      notyf[msg[0]](msg[1]);
    });
  }
}
