import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';
import 'flatpickr/dist/plugins/monthSelect/style.css';
import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    flatpickr(this.element, {
      altInput: true,
      plugins: [
        new monthSelectPlugin({
          shorthand: false,
          dateFormat: 'Y-m-d',
          altFormat: 'M Y',
        }),
      ],
    });
  }
}
