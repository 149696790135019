import Flatpickr from 'flatpickr';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [];

  connect() {
    const options = {
      altInput: true,
      altFormat: 'D J M Y',
      enableTime: false,
      dateFormat: 'Y-m-d',
      locale: {
        firstDayOfWeek: 1, // start week on Monday
      },
    };
    if (this.element.dataset.minDate) {
      options.minDate = this.element.dataset.minDate;
    }
    Flatpickr(this.element, options);
  }
}
