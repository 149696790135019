import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.searchParamName = this.element.dataset.searchParamName;
    this.element.addEventListener('change', this.selectionChanged.bind(this));
  }

  selectionChanged() {
    const url = new URL(window.location);
    url.searchParams.set(this.searchParamName, this.element.value);
    window.location = url.href;
  }
}
