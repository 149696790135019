import $ from 'jquery';
import '../../leads/grid.scss';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['datatable'];

  connect() {
    this.datatable = $(this.datatableTarget).DataTable({
      paging: false,
      searching: false,
    });
    this.datatable.order([7, 'desc']).draw();
  }
}
