import { Controller } from 'stimulus';
import $ from 'jquery';

export default class extends Controller {
  static targets = ['previewContainer'];

  clearPreview() {
    this.setPreview('');
  }

  setPreview(html) {
    const doc = this.previewContainerTarget.contentWindow.document;
    doc.open();
    doc.write(html);
    doc.close();
  }

  htmlDecode(encodedHtml) {
    const doc = new DOMParser().parseFromString(encodedHtml, 'text/html');
    return doc.documentElement.textContent;
  }

  async showSentEmail(e) {
    $('#show-customer-email-modal').modal('show');
    const ccPreviewUrl = e.currentTarget.dataset.ccPreviewUrl;
    const r = await fetch(ccPreviewUrl);
    const preview = await r.json();
    this.setPreview(this.htmlDecode(preview.preview_html));
  }
}
