import { show, hide } from '../../utils/display';
import {
  addRequiredAttributesToInputs,
  removeRequiredAttributesFromInputs,
} from '../../utils/requiredAttributes';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'collectionFrequency',
    'containerQty',
    'containerSize',
    'containerType',
    'deleteFlag',
    'numCollectionsPerWeek',
    'numCollectionsPerWeekDiv',
    'wcdForm',
  ];

  connect() {
    this.containerMapping = JSON.parse(this.element.dataset.containerMapping);
    this.wasteTypeVisible = this.element.dataset.wasteTypeVisible === 'true';
    this.containerTypeTarget.addEventListener(
      'change',
      this.containerTypeUpdated.bind(this)
    );
    this.collectionFrequencyTarget.addEventListener(
      'change',
      this.frequencyUpdated.bind(this)
    );
  }

  containerTypeUpdated(e) {
    const selectedValue = e.currentTarget.value;
    const options = selectedValue ? this.containerMapping[selectedValue] : [];
    this.containerSizeTarget
      .querySelectorAll('option')
      .forEach((option) => option.remove());

    if (options.length > 1) {
      this.containerSizeTarget.add(new Option('Please select size', ''));
      options.map((o) =>
        this.containerSizeTarget.add(
          new Option(o.container_size.replace(/_/gi, ' '), o.container_size)
        )
      );
      this.containerSizeTarget.disabled = false;
    } else {
      this.containerSizeTarget.add(new Option('Not applicable', ''));
      this.containerSizeTarget.disabled = true;
    }

    // for bags we want daily
    if (selectedValue === 'bags') {
      this.collectionFrequencyTarget.value = 'daily';
      this.collectionFrequencyTarget.disabled = true;
      this.collectionFrequencyTarget.required = false;
      this.frequencyUpdated({
        currentTarget: this.collectionFrequencyTarget,
      });
    } else {
      this.collectionFrequencyTarget.value = '';
      this.collectionFrequencyTarget.disabled = false;
      this.collectionFrequencyTarget.required = true;
      this.frequencyUpdated({
        currentTarget: this.collectionFrequencyTarget,
      });
    }
  }

  frequencyUpdated(e) {
    if (e.currentTarget.value === 'daily') {
      show(this.numCollectionsPerWeekDivTarget, { addRequired: true });
    } else {
      hide(this.numCollectionsPerWeekDivTarget, { removeRequired: true });
    }
  }

  yesNoToggled(e) {
    if (e.currentTarget.value === 'no') {
      this.deleteFlagTarget.value = 1;
      hide(this.wcdFormTarget, {});
      removeRequiredAttributesFromInputs(this.wcdFormTarget);
    } else {
      this.deleteFlagTarget.value = 0;
      show(this.wcdFormTarget, {});
      const inputsToMakeRequired = [
        this.containerSizeTarget,
        this.containerTypeTarget,
        this.containerQtyTarget,
        this.collectionFrequencyTarget,
      ];
      if (this.collectionFrequencyTarget.value == 'daily') {
        inputsToMakeRequired.push(this.numCollectionsPerWeekTarget);
      }
      addRequiredAttributesToInputs(this.wcdFormTarget, inputsToMakeRequired);
    }
  }
}
