import $ from 'jquery';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['datatable'];

  connect() {
    this.datatable = $(this.datatableTarget).DataTable({
      paging: false,
      searching: false,
      order: [[2, 'desc']],
    });
  }
}
