import Flatpickr from 'flatpickr';
import moment from 'moment';
import { Controller } from 'stimulus';
import { show, hide } from '../../utils/display';

export default class extends Controller {
  static targets = [
    'callbackDayRadio',
    'callbackDatePicker',
    'callbackDatePickerDiv',
    'timeslot',
  ];

  connect() {
    const minDate = moment().toDate();
    const maxDate = moment().add(14, 'days').toDate();
    this.datepicker = Flatpickr(this.callbackDatePickerTarget, {
      enableTime: false,
      minDate,
      maxDate,
      dateFormat: 'D J M Y',
      disable: [
        (date) => {
          return date.getDay() === 0 || date.getDay() === 6;
        },
      ],
      locale: {
        firstDayOfWeek: 1, // start week on Monday
      },
      onChange: () => {
        this.updateSlotAvailability();
      },
    });
  }

  callbackDayChanged(e) {
    if (e.currentTarget.value === 'Another day') {
      show(this.callbackDatePickerDivTarget, { addRequired: true, flex: true });
    } else {
      // dont need the date picker they've selected today or tomorrow
      hide(this.callbackDatePickerDivTarget, {
        removeRequired: true,
        flex: true,
      });
    }
    this.updateSlotAvailability();
  }

  endOfSlotHour({ timeslotString }) {
    const endOfSlotString = timeslotString.split('-')[1];
    return parseInt(endOfSlotString.split('.')[0]);
  }

  dayRadioTodaySelected() {
    return (
      this.callbackDayRadioTargets.find((e) => e.checked).value === 'Today'
    );
  }

  datePickerTodaySelected() {
    if (this.datepicker.selectedDates.length < 1) return;

    const today = moment().startOf('day').toDate();
    return this.datepicker.selectedDates[0].toString() === today.toString();
  }

  updateSlotAvailability() {
    // if they've selected today remove the slots already passed
    const slotForToday =
      this.dayRadioTodaySelected() || this.datePickerTodaySelected();
    const currentHour = moment().hour();
    this.timeslotTargets.forEach((e) => {
      const disabled =
        slotForToday &&
        this.endOfSlotHour({ timeslotString: e.value }) <= currentHour;
      e.disabled = disabled ? 'disabled' : false;
    });
  }
}
