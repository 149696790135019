import $ from 'jquery';
import 'select2/dist/js/select2.full';
import 'select2/dist/css/select2.css';
import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    const { leadSearchPath } = this.element.dataset;
    $(this.element).select2({
      placeholder: 'Search by email or company name',
      allowClear: true,
      minimumInputLength: 3,
      width: '300px',
      ajax: {
        url: leadSearchPath,
        dataType: 'json',
      },
    });
    $(this.element).on('select2:select', (e) => {
      window.location = e.params.data.url;
    });
    $(this.element).on('select2:open', (e) => {
      $('.select2-search__field')[0].focus();
    });
  }
}
