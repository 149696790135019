import Rails from '@rails/ujs';
import { Loader } from '@googlemaps/js-api-loader';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['lng', 'lat', 'map', 'companyName', 'siteName', 'postcode'];

  connect() {
    this.loader = new Loader({
      apiKey: this.element.dataset.googleMapsApiKey,
    });
    this.siteName = this.siteNameTarget.value || this.companyNameTarget.value;
    this.loader.load().then(this.loadSiteMap.bind(this));
  }

  async loadSiteMap() {
    this.geocoder = new google.maps.Geocoder();
    this.siteMap = new google.maps.Map(this.mapTarget, { zoom: 16 });
    this.latLng = await this.getMapLocation();
    this.siteMap.setCenter(this.latLng);
    this.siteMarker = new google.maps.Marker({
      position: this.latLng,
      map: this.siteMap,
      title: this.siteName,
      draggable: true,
    });
    this.siteMarker.addListener('click', this.showInfoWindow.bind(this));
    this.siteMarker.addListener('dragend', this.markerDragged.bind(this));
  }

  async getMapLocation() {
    if (this.lngTarget.value && this.latTarget.value) {
      return new google.maps.LatLng({
        lng: Number(this.lngTarget.value),
        lat: Number(this.latTarget.value),
      });
    }

    const result = await this.geocoder.geocode({
      address: this.postcodeTarget.value,
      region: 'gb',
    });
    return result.results[0].geometry.location;
  }

  markerDragged(e) {
    this.latLng = e.latLng;
    this.showInfoWindow();
  }

  showInfoWindow() {
    const infowindow = new google.maps.InfoWindow({
      content: `${this.siteName} ${this.latLng}`,
    });
    infowindow.open({
      anchor: this.siteMarker,
      map: this.siteMap,
      shouldFocus: false,
    });
  }

  async updateSiteLocation() {
    this.lngTarget.value = this.latLng.lng();
    this.latTarget.value = this.latLng.lat();
    Rails.fire(this.latTarget.form, 'submit');
  }
}
