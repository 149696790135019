import { Application } from 'stimulus';
import { definitionsFromContext } from 'stimulus/webpack-helpers';
// import axios from 'axios';
// import humps from 'humps';
// const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
// axios.defaults.headers.common['X-CSRF-Token'] = token;
// axios.defaults.headers.common.Accept = 'application/js';
// axios.interceptors.request.use((config) => {
//   config.data = humps.decamelizeKeys(config.data);
//   return config;
// });
// axios.interceptors.response.use((response) => {
//   response.data = humps.camelizeKeys(response.data);
//   return response;
// });

const application = Application.start();
const context = require.context('controllers', true, /_controller\.js$/);
application.load(definitionsFromContext(context));
