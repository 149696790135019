import { baseUrl, wasteCarriersRegsisterPath } from 'public_register';
import $ from 'jquery';
import TomSelect from 'tom-select';
import 'tom-select/dist/css/tom-select.bootstrap4.css';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'currentProvider',
    'existingService',
    'serviceProviderFields',
  ];

  connect() {
    this.existingServiceTargets.forEach((el) => {
      if (el.checked) {
        this.existingServiceUpdated({ currentTarget: el });
      }
      el.addEventListener('change', this.existingServiceUpdated.bind(this));
    });
    new TomSelect(this.currentProviderTarget, {
      create: true,
      placeholder: 'Please type the name of your supplier',
      valueField: 'id',
      labelField: 'title',
      searchField: 'title',
      sortField: 'title',
      loadThrottle: 1000,
      load: (query, callback) => {
        const queryString = `name-search=${encodeURIComponent(query)}`;
        const url = `${baseUrl}/${wasteCarriersRegsisterPath}?${queryString}`;
        fetch(url)
          .then((response) => response.json())
          .then((json) => {
            const items = json.items.map((i) => {
              return { id: i.holder.name, title: i.holder.name };
            });
            callback(items);
          })
          .catch(() => {
            callback();
          });
      },
      shouldLoad: (query) => query.length > 3,
    });
  }

  existingServiceUpdated({ currentTarget: { value: service } }) {
    const showOrHide = service !== 'false' ? 'show' : 'hide';
    $(this.serviceProviderFieldsTarget).collapse(showOrHide);
    this.currentProviderTarget.required = showOrHide === 'show';
  }
}
