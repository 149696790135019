import $ from 'jquery';
import { Controller } from 'stimulus';

export default class extends Controller {
  show(event) {
    const { callbackId } = event.currentTarget.dataset;
    if (callbackId) {
      $(`#callback-radio-${callbackId}`)[0].checked = true;
    } else {
      $('#callback-radio-unscheduled')[0].checked = true;
    }

    $('#schedule').collapse('hide');
    $('#schedule-callback-no')[0].checked = true;
    $('#outcome-did-not-answer')[0].checked = true;
    $('#call-outcomes-modal').modal('show');
  }
}
