import Rails from '@rails/ujs';
import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.element.addEventListener('change', this.submitForm.bind(this));
  }

  submitForm(e) {
    Rails.fire(e.currentTarget.form, 'submit');
  }
}
