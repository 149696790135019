import { Controller } from 'stimulus';

/* eslint class-methods-use-this:
  ["error", { "exceptMethods": ["openChat"] }] */
export default class extends Controller {
  openChat(event) {
    event.preventDefault();
    window.Tawk_API.showWidget();
    window.Tawk_API.maximize();
  }
}
