import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['collectionSiteInfo', 'siteRisks'];

  collectionSiteToggled(e) {
    const disabled = !e.currentTarget.checked;
    this.element.querySelectorAll('.collection-site').forEach((el) => {
      if (disabled) {
        el.setAttribute('disabled', 'disabled');
        this.collectionSiteInfoTarget.classList.add('d-none');
        this.collectionSiteInfoTarget.classList.remove('d-block');
        this.siteRisksTarget.classList.add('d-none');
        this.siteRisksTarget.classList.remove('d-block');
      } else {
        el.removeAttribute('disabled');
        this.collectionSiteInfoTarget.classList.remove('d-none');
        this.collectionSiteInfoTarget.classList.add('d-block');
        this.siteRisksTarget.classList.remove('d-none');
        this.siteRisksTarget.classList.add('d-block');
      }
    });
  }
}
