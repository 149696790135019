import Rails from '@rails/ujs';
import { Controller } from 'stimulus';

export default class extends Controller {
  siteSelected(e) {
    Rails.fire(e.currentTarget.form, 'submit');
  }

  useCompanyDdDetailsToggled(e) {
    const useCompanyDetails = e.currentTarget.checked;
    this.element.querySelectorAll('.dd-form-field').forEach((el) => {
      if (useCompanyDetails) {
        el.setAttribute('disabled', 'disabled');
      } else {
        el.removeAttribute('disabled');
      }
    });
  }
}
