import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [];

  connect() {
    // on timeout, redirect to a path passed in
    const { onTimeoutPath } = this.element.dataset;
    this.onTimeout = () => {
      document.location = onTimeoutPath;
    };
    // setup polling for quote readiness
    this.quoteReadyPath = this.element.dataset.quoteReadyPath;
    this.numberTimesPolled = 0;
    this.pollFrequencyMs = 5000; // 5 seconds
    this.maxTimeWaitingMs = 90000; // 90 seconds
    this.nowPolling = false;
    this.pollForQuoteReady = () => {
      this.quoteReady = setTimeout(
        this.checkQuoteReady.bind(this),
        this.pollFrequencyMs
      );
    };
    this.pollForQuoteReady();
  }

  disconnect() {
    clearTimeout(this.quoteReady);
  }

  async checkQuoteReady() {
    // dont check if there is a check running
    if (this.nowPolling) {
      return;
    }

    // work out how long we've been polling for and check if we should timeout
    this.nowPolling = true;
    this.numberTimesPolled += 1;
    this.timeWaitingForQuote = this.numberTimesPolled * this.pollFrequencyMs;
    if (this.timeWaitingForQuote >= this.maxTimeWaitingMs) {
      // polling too long, lets timeout
      this.onTimeout();
      return;
    }

    // check to see if quote is ready
    try {
      const r = await fetch(this.quoteReadyPath);
      const body = await r.json();
      if (body.ready === true) {
        // ready lets show quote
        document.location = body.redirect_to;
      }
      this.pollForQuoteReady();
    } catch {
      // problem checking quote lets timeout early
      this.onTimeout();
    } finally {
      // mark this check as complete, we are no longer polling
      this.nowPolling = false;
    }
  }
}
