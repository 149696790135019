import $ from 'jquery';
import Cleave from 'cleave.js';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'accountName',
    'accountNumber',
    'bankAddress',
    'form',
    'sortCode',
    'bankAddressContainer',
  ];

  connect() {
    this.bankLookupUrl = this.element.dataset.bankLookupUrl;
    this.sortCode = new Cleave(this.sortCodeTarget, {
      delimiter: '-',
      blocks: [2, 2, 2],
    });

    // don't use the js-validate class on the form as we want to initialise
    // and customize the validation here
    this.validation = $.HSCore.components.HSValidation.init($(this.formTarget));
    $.HSCore.components.HSValidation.rules($(this.accountNumberTarget), 'add', {
      required: true,
      digits: true,
      minlength: 8,
      maxlength: 8,
    });
    $.HSCore.components.HSValidation.rules($(this.accountNameTarget), 'add', {
      required: true,
      minlength: 3,
    });
    $.HSCore.components.HSValidation.rules($(this.sortCodeTarget), 'add', {
      required: true,
      minlength: 8,
      maxlength: 8,
    });
  }

  async search(e) {
    e.preventDefault();
    const sortCode = this.sortCode.getRawValue();
    if (this.accountNumberTarget.value.length === 8 && sortCode.length === 6) {
      const r = await fetch(
        `${this.bankLookupUrl}?account_number=${this.accountNumberTarget.value}&sort_code=${sortCode}`
      );
      const bankDetails = await r.json();
      this.bankAddressTarget.value = `${
        bankDetails.name
      }, \n${bankDetails.address
        .split(',')
        .map((s) => s.trim())
        .join('\n')} `;
      this.bankAddressContainerTarget.classList.remove('d-none');
    }
  }

  manualEdit() {
    // turn off validation of top form
    this.validation.destroy();
    this.bankAddressContainerTarget.classList.remove('d-none');
  }
}
