import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'confirmed',
    'line1',
    'line2',
    'city',
    'county',
    'postcode',
    'addressSelect',
    'addressFields',
  ];

  connect() {
    this.addressLookupUrl = this.element.dataset.addressLookupUrl;
    this.addressSelectTarget.addEventListener(
      'change',
      this.addressSelected.bind(this)
    );
  }

  addressSelected(e) {
    if (e.currentTarget.selectedIndex < 1) {
      this.clearAddress();
      return;
    }

    const address = JSON.parse(
      this.addressSelectTarget[e.currentTarget.selectedIndex].dataset.address
    );
    this.line1Target.value = address.line_1;
    this.line2Target.value = address.line_2;
    this.cityTarget.value = address.town_or_city;
    this.countyTarget.value = address.county;
    this.postcodeTargets.forEach((e) => {
      e.value = address.postcode;
    });
    if (this.hasAddressFieldsTarget) {
      this.addressFieldsTarget.classList.remove('d-none');
    }
    if (this.hasConfirmedTarget) {
      this.confirmedTarget.value = true;
    }
  }

  clearAddress() {
    this.line1Target.value = null;
    this.line2Target.value = null;
    this.cityTarget.value = null;
    this.countyTarget.value = null;
    this.postcodeTargets.forEach((e) => {
      e.value = null;
    });
    if (this.hasConfirmedTarget) {
      this.confirmedTarget.value = false;
    }
  }

  async addressLookup(e) {
    e.preventDefault();
    const r = await fetch(
      `${this.addressLookupUrl}?postcode=${this.postcodeTarget.value}`
    );
    const addresses = await r.json();
    this.addressSelectTarget
      .querySelectorAll('option')
      .forEach((option) => option.remove());
    if (addresses && addresses.length > 0) {
      this.addressSelectTarget.add(
        new Option('Please confirm your collection address', '')
      );
      addresses.forEach((a) => {
        const option = new Option(a.text, a.id);
        option.dataset.address = JSON.stringify(a);
        this.addressSelectTarget.add(option);
      });
    } else {
      this.addressSelectTarget.add(
        new Option('Could not find addresses for postcode supplied', '')
      );
    }
    if (addresses.length && this.hasAddressFieldsTarget) {
      this.addressFieldsTarget.classList.add('d-none');
    }
  }

  manualEdit() {
    this.addressFieldsTarget.classList.remove('d-none');
  }
}
