import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import Rails from '@rails/ujs';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['dateSelectButton'];

  connect() {
    this.dateSelectButtonTarget.addEventListener('click', (e) => e.preventDefault());
    this.datePicker = flatpickr(this.element, {
      wrap: true,
      positionElement: this.dateSelectButtonTarget,
      onChange: this.submitForm.bind(this),
    });
  }

  submitForm(selectedDates, dateStr, flatpickr) {
    if (selectedDates.length !== 1 || dateStr === this.lastSelectedDateStr) return;

    this.lastSelectedDateStr = dateStr;
    Rails.fire(flatpickr.input.form, 'submit');
  }
}