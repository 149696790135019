import $ from 'jquery';
import '../../leads/grid.scss';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['datatable'];

  connect() {
    this.datatable = $(this.datatableTarget).DataTable({
      lengthChange: false,
      order: [[0, 'desc']],
    });
    this.datatable.page.len(50).draw();
  }
}
