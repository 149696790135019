import $ from 'jquery';
import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    if (window.location.hash) {
      // activate tab
      $(`a[href="${window.location.hash}"]`).tab('show');
    }
  }

  tabSelected(e) {
    const { hash } = new URL(e.currentTarget.href);
    window.history.pushState(null, null, hash);
  }
}
