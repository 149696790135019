import $ from 'jquery';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [];

  connect() {
    $.HSCore.components.HSFlatpickr.init($(this.element), {
      enableTime: true,
      minTime: '09:00',
      maxTime: '17:30',
      dateFormat: 'd M Y H:i'
    });
  }
}
