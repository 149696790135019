import Cleave from 'cleave.js';
import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    new Cleave(this.element, {
      date: true,
      datePattern: ['Y', 'm'],
    });
  }
}
