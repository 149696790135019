import $ from 'jquery';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [];

  connect() {
    this.customerNeedsAttentionPath =
      this.element.dataset.customerNeedsAttentionPath;
    this.element.addEventListener('click', this.needHelpClicked.bind(this));
  }

  async needHelpClicked() {
    await fetch(this.customerNeedsAttentionPath);
    $('#need-help-modal').modal('show');
  }
}
