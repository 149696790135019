import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['confirmButton', 'requiredField'];

  connect() {
    this.checkRequiredFieldsAndEnableConfirm();
    this.requiredFieldTargets.forEach((el) =>
      el.addEventListener(
        'change',
        this.checkRequiredFieldsAndEnableConfirm.bind(this)
      )
    );
  }

  checkRequiredFieldsAndEnableConfirm() {
    const notReady = this.requiredFieldTargets.some((el) => el.value == '');
    if (notReady) {
      this.confirmButtonTarget.setAttribute('disabled', 'disabled');
    } else {
      this.confirmButtonTarget.removeAttribute('disabled');
    }
  }
}
