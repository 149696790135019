import { hide, show } from '../../utils/display';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'companyAddressSelection',
    'wasteTypeCheckbox',
    'createQuoteButton',
  ];

  connect() {
    this.checkedCount = 0;
    this.wasteTypeCheckboxTargets.forEach((e) => {
      const checked = e.checked;
      this.checkedCount += checked ? 1 : 0;
      this.enableCreateQuoteButton();
      e.addEventListener('change', this.checkboxChecked.bind(this));
    });
  }

  checkboxChecked(e) {
    const checked = e.currentTarget.checked;
    this.checkedCount += checked ? 1 : -1;
    this.enableCreateQuoteButton();
  }

  enableCreateQuoteButton() {
    const enabled = this.checkedCount > 0 ? true : false;
    if (enabled) {
      this.createQuoteButtonTarget.removeAttribute('disabled');
    } else {
      this.createQuoteButtonTarget.setAttribute('disabled', 'disabled');
    }
  }

  sameAddressCheckboxToggled(e) {
    const checked = e.currentTarget.checked;
    if (checked) {
      hide(this.companyAddressSelectionTarget, {});
    } else {
      show(this.companyAddressSelectionTarget, {});
    }
  }
}
