import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['financeContactForm'];

  copyFinanceContact(e) {
    const financeContactInputs =
      this.financeContactFormTarget.querySelectorAll('input');
    if (e.currentTarget.checked) {
      financeContactInputs.forEach((el) =>
        el.setAttribute('disabled', 'disabled')
      );
    } else {
      financeContactInputs.forEach((el) => el.removeAttribute('disabled'));
    }
  }
}
